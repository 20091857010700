<template>
  <div class="addthisclass11">
    <Header />
    <!-- <Nav /> -->
    <div class="orderTitle">
      <div class="logo" @click="jumpToHome"></div>
      <span class="title">结算页</span>
    </div>
    <div class="serviceOrder">
      <div style="width: 10rem; margin:0 auto;margin-top: 0.6rem;">
        <ReturnStep :curStep="1" :stepList="stepList" />
      </div>
      <div class="order-container">
        <p style="font-size: 0.19rem;color:#737373">填写并核对商品信息</p>
        <div class="order">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="非到店服务">
              <div id="nonVisit">
                <div style="position:absolute;right:0">
                  <a @click="e=>{e.preventDefault();visible=true;}" style="font-size:0.16rem">
                    <a-icon type="plus-circle" style="margin-right:0.03rem;font-weight:normal" />新增收货地址
                  </a>
                </div>
                <div class="form-title" style="border:0;padding-top: 0;">
                  <span style="color: #171717;font-size:0.16rem;font-weight: bold;">收货地址信息</span>
                </div>

                <a-radio-group
                  v-model="currAddress"
                  @change="currAddressChange($event)"
                  v-if="showAddressList.length>0"
                >
                  <a-radio
                    v-for="(item,index) in showAddressList"
                    :key="index"
                    :style="radioStyle"
                    :value="item"
                  >
                    {{item.receiverProvinceName}}
                    {{item.receiverCityName}} {{item.receiverAreaName}} {{item.receiverStreetName}} {{item.receiverDetailAddress}} {{item.receiverName}} {{item.phoneNumber}}
                    <span
                      class="moren"
                      v-if="index==0"
                    >默认</span>
                  </a-radio>
                </a-radio-group>
                <p class="showmore" v-if="serviceAddressList.length>3">
                  <span
                    v-if="showAddressList.length==3"
                    @click="showAddressList=serviceAddressList"
                  >
                    更多收货地址
                    <a-icon type="down" />
                  </span>
                  <span
                    v-if="showAddressList.length>3"
                    @click="showAddressList=serviceAddressList.slice(0,3)"
                  >
                    收起更多收货地址
                    <a-icon type="up" />
                  </span>
                </p>
              </div>
            </a-tab-pane>
          </a-tabs>

          <div class="form-title">
            <span style="color: #171717;font-size:0.16rem;font-weight: bold;">商品订单信息</span>
          </div>
          <table class="table-content">
            <thead>
              <tr class="table-title">
                <td>商品名称</td>
                <td>商品属性</td>
                <td>商品价格</td>
                <td>数量</td>
                <td>优惠方式</td>
                <td>小计</td>
              </tr>
            </thead>

            <tbody class="list-item">
              <tr>
                <td colspan="6" style="text-align:left">
                  <p class="title">店铺：{{serviceOrder.storeName}}</p>
                </td>
              </tr>
              <tr class="item-row">
                <td>
                  <div class="left-box">
                    <div class="img">
                      <img class="img" :src="`${this.serviceOrder.serviceImage}`" alt />
                    </div>
                    <span>{{ serviceOrder.serviceName }}</span>
                  </div>
                </td>
                <td style="text-align:left;line-height:.35rem">
                  服务方式：{{serviceOrder.serviceStyleList[0].serviceStyleName }}
                  <br />
                  <label
                    v-if="serviceOrder.serviceStyleList[0].serviceStyleName.includes('上门服务')"
                  >服务范围：{{serviceOrder.serviceScope }}</label>
                </td>
                <td>{{ serviceOrder.servicePrice }}</td>
                <td>
                  <div id="num">
                    <span
                      class="mountOpera"
                      style="border-right:1px solid #ccc"
                      @click="()=>{amount<=1?1:+amount--}"
                    >-</span>
                    {{ amount }}
                    <span
                      class="mountOpera"
                      style="border-left:1px solid #ccc"
                      @click="()=>{amount>=99?99:+amount++}"
                    >+</span>
                  </div>
                </td>
                <td>{{ "--" }}</td>
                <td
                  style="color:#ee2828;text-align:right;fontSize:0.17rem;"
                >{{+amount*serviceOrder.servicePrice}}</td>
              </tr>
            </tbody>
          </table>
          <div class="fapiao-box">
            <div class="left">
              <a-checkbox
                :checked="fapiao"
                @change="onChange"
                style="margin-bottom: 0.16rem;color: #171717; font-size: 0.16rem;"
              >开具发票</a-checkbox>
              <div v-if="fapiao" class="form-box">
                <ul>
                  <li>
                    <span>发票类型：</span>
                    <span style="color:#171717">电子发票</span>
                    <br />
                    <a @click="()=>{return false}" style="margin-left:1.5rem">电子发票金额范围以及使用说明</a>
                  </li>
                  <li>
                    <span>发票信息：</span>
                    <a-radio-group v-model="invoiceContent">
                      <a-radio :value="0" style="font-size:0.16rem">服务明细</a-radio>
                      <a-radio :value="1" style="font-size:0.16rem">服务类别</a-radio>
                    </a-radio-group>
                  </li>
                  <li>
                    <span>发票抬头类型：</span>
                    <a-radio-group v-model="invoiceTitleType" @change="radioChange">
                      <a-radio :value="0" style="font-size:0.16rem">个人</a-radio>
                      <a-radio :value="1" style="font-size:0.16rem">企业</a-radio>
                    </a-radio-group>
                  </li>
                  <li>
                    <span>发票抬头：</span>
                    <span v-if="!invoiceTitleChangeBool">{{invoiceTitle}}</span>

                    <a-input
                      style="width: 3rem;height:0.35rem"
                      v-model="invoiceTitle"
                      v-if="invoiceTitleChangeBool"
                      @keydown.enter="invoiceTitleChangeBool=false"
                    />&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      v-if="!invoiceTitleChangeBool"
                      @click="e=>{e.preventDefault();invoiceTitleChangeBool=true;}"
                    >修改</a>
                    <a
                      v-if="invoiceTitleChangeBool"
                      @click="e=>{e.preventDefault();invoiceTitleChangeBool=false;}"
                    >确认</a>
                  </li>
                  <li>
                    <span>纳税人识别号：</span>
                    <a-input style="width: 3rem;height:0.35rem" v-model="taxpayerId" />
                    <br />
                    <span
                      style="margin-left:1.5rem;font-size:0.16rem;color:#acacac;margin-right:0.2rem"
                    >根据最新增值税管理办法，如需企业抬头发票，请填写有效税号信息</span>
                  </li>
                  <li>
                    <span>收票人邮箱：</span>
                    <a-input style="width: 3rem;height:0.35rem" v-model="receiverEmail" />
                  </li>
                </ul>
              </div>
              <div style="display: flex;">
                <span style="color: #171717; font-size: 0.16rem;width:1rem">服务备注：</span>
                <a-textarea
                  style="width:4.2rem;margin-right:1.72rem;font-size:0.16rem"
                  v-model="serviceRemark"
                  placeholder="建议留言前先和服务商沟通"
                  :rows="3"
                />
              </div>
            </div>

            <div class="right">
              <div class="youhui">
                <span>服务商优惠：--</span>
                <span style="color: #EE2828;font-size:0.17rem;">0.00</span>
              </div>
              <div class="youhui">
                <span>服务费：</span>
                <span
                  style="color: #EE2828;font-size:0.17rem;"
                >{{serviceOrder.serviceStyleList[0].serviceFee|formatMoney}}</span>
              </div>
            </div>
          </div>
          <div style="padding: 0.26rem 0;text-align: end;">
            <span style="color: #171717;font-size:0.16rem;margin-right:0.26rem;">服务商合计（含服务费）</span>
            <span style="color: #EE2828;font-size:0.17rem;">¥{{ totalAmount|formatMoney }}</span>
          </div>
          <div class="form-title">
            <span style="color: #171717;font-size:0.16rem;font-seight: bold;">付款方式</span>
          </div>
          <div class="paytype">
            <!-- <div
              :class="['box', payType === 1 && 'active']"
              @click="payTypeChange(1)"
            >
              货到付款
            </div>-->
            <div :class="['box', payType === 285 && 'active']" @click="payTypeChange(285)">在线支付</div>
            <div
              hidden
              :class="['box', payType === 286 && 'active']"
              @click="payTypeChange(286)"
            >现场付款</div>
            <div
              hidden
              :class="['box', payType === 287 && 'active']"
              @click="payTypeChange(287)"
            >货到付款</div>
          </div>
          <div class="form-title">
            <span style="color: #171717;font-size:0.16rem;font-weight: bold;">优惠券信息</span>
            <span style="color: #737373;font-size:0.16rem;margin-left:0.2rem;">暂无可用优惠券</span>
          </div>
          <div class="form-title">
            <span style="color: #171717;font-size:0.16rem;fontweight: bold;">代币信息</span>
          </div>
          <a-checkbox
            style="margin-left: 0.2rem;color: #171717; font-size: 0.16rem;"
            @change="checkBoxChange"
            v-model="useDaibiBool"
          >
            使用代币
            <span style="color: #737373">
              （账户当前余额：
              <span style="font-size:0.16rem;color:#E22B2B;">¥{{ availableDaibi }}</span>元，本次使用：
              <span
                style="font-size:0.16rem;color:#E22B2B;"
              >¥{{ useDaibiBool?availableDaibi:0 }}</span>元）
            </span>
          </a-checkbox>
        </div>
        <div class="paymoney">
          <div class="left">
            <p>共1项服务，总服务金额：</p>
            <p>服务优惠：</p>
          </div>
          <div class="right" style="color:#d91241;font-size:0.17rem">
            <p>￥{{ totalAmount |formatMoney }}</p>
            <p>-￥0.00</p>
          </div>
        </div>
        <div class="end">
          <div class="money">
            <p style="line-height:0.4rem">应付总金额：</p>
            <p
              style="color: #D91241;width: 1.57rem;text-align: right;font-size:0.26rem"
            >￥{{ totalAmountAll |formatMoney }}</p>
          </div>
          <div
            style="font-size:0.16rem;color:#ACACAC;"
            v-if="activeType=='1'&&currAddress['receiverProvinceName']"
          >
            <span style="margin-right: 0.24rem;">
              服务地址：
              {{ currAddress['receiverProvinceName'] }}
              {{ currAddress['receiverCityName'] }}
              {{ currAddress['receiverAreaName'] }}
              {{ currAddress['receiverStreetName'] }}
              {{ currAddress['receiverDetailAddress'] }}
            </span>
            服务人： {{ currAddress['receiverName'] }}
            {{ currAddress['phoneNumber'] }}
          </div>

          <div style="font-size:0.16rem;color:#999;" v-if="activeType=='2'">
            <span style="margin-right: 0.24rem;">
              门店名：
              {{ selectShop.storeName }}
              门店电话：
              {{ selectShop.contactPhone }}
              营业时间：
              {{ selectShop.openTime }}~ {{ selectShop.closeTime }}
              <br />
              门店地址：
              {{ selectShop.storeAddress }}
            </span>
          </div>
        </div>
        <div style="text-align:right;padding-right:0.45rem;margin-bottom: 2.8rem;">
          <a-button
            type="primary"
            style="width: 2rem;height:0.44rem;margin-top:0.24rem;"
            @click="serviceOrderPay"
          >去支付</a-button>
          <!-- @click="$router.push('/serviceOrderPay')" -->
        </div>
      </div>
    </div>
    <Footer />

    <a-modal width="800px" style="top: 200px;" v-model="visible" @ok="handleOk">
      <template slot="title">
        <div class="modal-title">
          <p style="font-size: 20px;color:#333333;">
            <i />新增服务信息
          </p>
        </div>
      </template>
      <div id="addAddressBox">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item v-bind="formItemLayout" label="服务地区">
            <a-cascader
              :options="options"
              style="width: 6rem;font-size:0.16rem"
              placeholder="服务地区"
              :loadData="loadData"
              v-decorator="[
                'address',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务地区'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="详细地址">
            <a-input
              style="width: 6rem;"
              v-decorator="[
                'receicerDetailAddress',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写详细地址！'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="邮政编码">
            <a-input style="width: 6rem;" v-decorator="['zipCode']" />
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="联系人">
            <a-input
              style="width: 6rem;"
              v-decorator="[
                'receiverName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写联系人！'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="联系电话">
            <a-input
              style="width: 6rem;"
              v-decorator="[
                'phoneNumebr',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写联系电话！'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <!-- <a-input style="width: 4rem;" v-decorator="['zipCode']" /> -->
            <a-checkbox v-decorator="['isDefaut']">设置为默认服务信息</a-checkbox>
          </a-form-item>
        </a-form>
      </div>
      <template slot="footer">
        <div class="modal-footer">
          <a-button key="back" style="width:135px;" @click="handleOk">取消</a-button>
          <a-button key="submit" style="width:135px;" type="primary" @click="handleSubmit">保存</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
  import Vue from "vue";
  // import Nav from "@/components/Nav";
  import Footer from "@/components/Footer";
  import Header from "@/components/Header";
  import {Button, Cascader, Checkbox, Form, Input, message, Modal, Select, Upload} from "ant-design-vue";
  import ReturnStep from "@/components/ReturnStep.vue";
  import {
    addAddress,
    fetchAddrCascader,
    fetchDetailByServiceId,
    getServiceAddressList,
    userdaibi
  } from "@/services/api";
  import {baseUrl, request} from "@/services/http";
  import AMapLoader from "@amap/amap-jsapi-loader";

  Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Cascader);
Vue.use(Upload);
Vue.use(Modal);

export default {
  name:"addthisclass11",
  components: {
    // Nav,
    Footer,
    Header,
    ReturnStep
  },
  data() {
    return {
      showTpbsaj: true,
      availableDaibi: 0,
      currAddress: {
        receiverProvinceName: "",
        receiverCityName: "",
        receiverAreaName: "",
        receiverStreetName: "",
        receiverDetailAddress: "",
        receiverName: "",
        phoneNumber: ""
      },
      serviceOrder: {},
      haveInvoice: 0,
      serviceRemark: "",
      serviceId: "",
      visible: false,
      payType: 285,
      invoiceTitleType: 0,
      invoiceContent: 0,
      invoiceTitle: "个人",
      receiverEmail: "",
      taxpayerId: "",
      amount: 0,
      // totalAmount: 95.0,
      tokenAmount: 11.2,
      isChecked: 0,
      fapiao: false,
      fapiao2: false,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 19 }
        }
      },
      stepList: [
        {
          name: "选购商品"
        },
        {
          name: "填写核对商品订单信息"
        },
        {
          name: "完成商品订单"
        }
      ],
      options: [],
      radioStyle: {
        display: "block",
        height: "0.3rem",
        lineHeight: "0.3rem",
        fontSize: "0.16rem",
        color: "#737373"
      },
      selectlist: { myAddress: "", shopAddress: "" },
      showAddressList: [],
      activeType: "1",
      serviceAddressList: [],
      useDaibiBool: false,
      shopListShow: [
        {
          storeName: "",
          contactPhone: "",
          openTime: "",
          storeAddress: "",
          closeTime: ""
        }
      ],
      shopList: [],
      selectShop: {},
      invoiceTitleChangeBool: false,
      AMap: undefined
    };
  },
  computed: {
    totalAmount() {
      return (
        this.serviceOrder.servicePrice * +this.amount +
        this.serviceOrder.serviceStyleList[0].serviceFee
      );
    },
    totalAmountAll() {
      return (
        this.serviceOrder.servicePrice * +this.amount +
        this.serviceOrder.serviceStyleList[0].serviceFee -
        (this.useDaibiBool ? +this.availableDaibi : 0)
      );
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },

  mounted() {
    this.userdaibi();
    this.getServiceAddressList();
    this.init();
    this.initAMap();
    // this.serviceOrder = this.$route.query;
    // this.serviceOrder = "";
    this.amount = this.$route.query.amount;
    this.serviceId = this.$route.query.serviceId;
    fetchDetailByServiceId({ serviceId: this.serviceId }).then(res => {
      if (res.code === 0) {
        this.serviceOrder = res.data.serviceInfo;
        if (this.serviceOrder.serviceStyleList.length == 1) {
          const t = setTimeout(() => {
            this.showTpbsaj = false;
            const el = document.getElementsByClassName("ant-tabs-nav-scroll");
            el[0].style.display = "none";
            const el2 = document.getElementsByClassName("ant-tabs-bar");
            el2[0].style.display = "none";
            clearTimeout(t);
          });
        }
        if (
          this.serviceOrder.serviceStyleList[0].serviceStyleId == "16" &&
          this.serviceOrder.serviceStyleList.length > 1
        ) {
          const arr = this.serviceOrder.serviceStyleList;
          this.serviceOrder.serviceStyleList = [
            ...arr.filter(item => item.serviceStyleId != "16"),
            arr.find(item => item.serviceStyleId == "16")
          ];
        }
        if (this.serviceOrder.serviceStyle.indexOf("到店服务") > -1) {
          this.getShopList();
          if (this.serviceOrder.serviceStyleList.length == 1) {
            this.activeType = "2";
          }
        }
      }
    });
  },
  methods: {
    currAddressChange(e) {
      this.currAddress = e.target.value;
    },
    //到店时 获取门店列表
    getShopList() {
      request(`${baseUrl}/api/v1/org/store-address-info`, {
        params: { orgId: this.serviceOrder.orgId }
      }).then(res => {
        if (res["code"] == 0) {
          this.shopList.push(res["data"]);
          this.selectShop = this.shopList[0];
          this.shopListShow = this.shopList.slice(0, 3);
        }
      });
    }, // 初始化地址
    init() {
      fetchAddrCascader({
        areaType: 0,
        areaId: null
      }).then(data => {
        if (data && data.code == 0) {
          const list = data.data.list;
          let children = [];
          if (list) {
            list.forEach(i => {
              children.push({
                label: i.name,
                value: i.id,
                id: i.id,
                level: 1,
                isLeaf: false
              });
            });
          }
          this.options = children;
        } else {
          message.error(data.msg);
        }
      });
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      const { id } = targetOption;
      const { level } = targetOption;
      targetOption.loading = true;
      fetchAddrCascader({
        areaType: level,
        areaId: id
      }).then(data => {
        targetOption.loading = false;
        if (data && data.code == 0) {
          const list = data.data.list;
          let children = [];
          if (list) {
            list.forEach(i => {
              children.push({
                label: i.name,
                value: i.id,
                id: i.id,
                isLeaf: i.childNum == 0,
                level: level + 1
              });
            });
          }
          targetOption.loading = false;
          targetOption.children = children;
          this.options = [...this.options];
        } else {
          message.error(data.msg);
        }
      });
    },
    initAMap() {
      AMapLoader.load({
        version: "1.4.15",
        plugins: ["AMap.Geocoder"],
        key: "c4ac5dbaa55b2994a57c6b369f28af53"
      })
        .then(AMap => {
          this.AMap = AMap;
        })

    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let param = {
            receiverProvince: values.address[0].toString(),
            receiverCity: values.address[1].toString(),
            receiverArea: values.address[2].toString(),
            receiverStreet: values.address[3].toString(),
            phoneNumber: values.phoneNumebr,
            receiverDetailAddress: values.receicerDetailAddress,
            receiverName: values.receiverName,
            zipCode: values.zipCode,
            isDefaut: values.isDefaut ? 1 : 0
          };
          let bufferStr = "",
            bufferArr = this.options;
          for (let item of values.address.slice(0, 3)) {
            let obj = bufferArr.find(val => val.id == +item);
            bufferArr = obj.children;
            bufferStr += obj.label;
          }
          const geocoder = new this.AMap.Geocoder({
            city: bufferStr,
            batch: true
          });
          geocoder.getLocation(
            values.receicerDetailAddress,
            (state, result) => {
              if ((state = "complete" && result.info == "OK")) {
                param.longitude = result.geocodes[0].location.lng;
                param.latitude = result.geocodes[0].location.lat;
              } else {
                param.longitude = 0;
                param.latitude = 0;
              }
              addAddress({
                ...param
              }).then(res => {
                if (res.code === 0) {
                  message.success("添加成功");
                  this.visible = false;
                  this.form.resetFields();
                  this.getServiceAddressList(values.isDefaut ? 0 : 1);
                } else {
                  message.error(res.msg);
                }
              });
            }
          );
        }
      });
    },
    // 获取省id
    getProviceId(name, paramName, arrayData) {
      return arrayData.filter(el => {
        return el[paramName] === name;
      });
    },
    userdaibi() {
      userdaibi().then(res => {
        this.availableDaibi = res.data.availableDaibi;
      });
    },
    getServiceAddressList(defaultA = 0) {
      getServiceAddressList().then(res => {
        this.serviceAddressList = res.data.serviceAddressList;
        this.showAddressList = this.serviceAddressList.slice(0, 3);
        if (this.showAddressList.length > 0)
          this.currAddress = this.showAddressList[defaultA];
      });
    },
    onChange(e) {
      this.fapiao = !this.fapiao;
      this.haveInvoice = this.fapiao ? 1 : 0;
    },
    onChange2() {
      this.fapiao2 = !this.fapiao2;
    },
    radioChange(e) {
      this.invoiceTitleType = e.target.value;
    },
    invoiceContentChange(e) {
      this.invoiceContent = e.target.value;
    },
    payTypeChange(key) {
      this.payType = key;
    },
    showModal() {
      this.visible = true;
    },

    handleOk() {
      this.visible = false;
    },
    jumpToHome() {
      this.$router.push("/");
    },
    serviceOrderPay() {
      let params = {
        paymentMethod: this.payType,
        totalCost: this.totalAmount,
        discountCoupon: 0.0,
        useToken: this.useDaibiBool ? 1 : 0,
        tokenAmount: this.availableDaibi,
        payTotal: this.totalAmountAll,
        couponList: [],
        serviceOrder: {
          orgId: this.serviceOrder.orgId,
          storeName: this.serviceOrder.storeName,
          haveInvoice: this.haveInvoice,
          orgDiscountPrice: 0.0,
          serviceRemark: this.serviceRemark,
          serviceOrderTotal: this.totalAmount,
          serviceInfo: {
            serviceId: this.serviceOrder.serviceId,
            serviceName: this.serviceOrder.serviceName,
            servicePrice: this.serviceOrder.servicePrice,
            ifRefund: this.serviceOrder.ifRefund,
            serviceFee: this.serviceOrder.serviceStyleList[0].serviceFee,
            serviceType: this.serviceOrder.serviceType,
            serviceStyle: this.serviceOrder.serviceStyleList[0].serviceStyleId,
            ptActivityDiscount: 0.0,
            serviceNum: this.amount
          },
          invoiceInfo: {
            invoiceType: 1,
            invoiceContent: this.invoiceContent,
            invoiceTitleType: this.invoiceTitleType,
            invoiceTitle: this.invoiceTitle,
            taxpayerId: this.taxpayerId,
            receiverEmail: this.receiverEmail
          }
        }
      };
      if (this.currAddress)
        (params["serviceAddressId"] = this.currAddress.addressId),
      request(`${baseUrl}/api/v1/one-service/settlement`, {
        method: "POST",
        data: params
      }).then(res => {
        if (res.code === 0) {
          message.success("提交成功");
          this.$router.push({
            path: "/serviceOrderPay",
            query: {
              orderInfo: JSON.stringify(res.data)
            }
          });
        } else {
          message.error(res.msg);
        }
      });
      // this.$router.push("/serviceOrderPay");
      // settlement({ ...params }).then(res => {
      //   if (res.code === 0) {
      //     message.success("提交成功");
      //     this.$router.push({
      //       path: "/serviceOrderPay",
      //       query: {
      //         orderInfo: json.stringify(res.data)
      //       }
      //     });
      //   } else {
      //     message.error(res.msg);
      //   }
      // });
    },
    checkBoxChange(e) {
      this.isChecked = e.target.checked ? 1 : 0;
    }
  }
};
</script>

<style lang="less" >
.orderTitle {
  width: 100vw;
  height: 1.2rem;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .logo {
    width: 2.43rem;
    background-image: url("../assets/images/logo.png");
    background-position: center center;
    background-size: 100%;
    cursor: pointer;
    margin-left: 4rem;
  }
  .title {
    font-size: 0.3rem;
    color: #171717;
    margin-left: 0.5rem;
  }
}
.serviceOrder {
  font-size: 0.16rem;
  .order-container {
    width: 11.06rem;
    margin: 0 auto;
    margin-top: 0.2rem;
    .order {
      width: 100%;
      border: 1px solid #ebeef3;
      padding: 0.3rem 0.4rem;

      #nonVisit {
        height: auto;

        .moren {
          background-color: #60c7c6;
          padding: 0.02rem 0.15rem 0.03rem;
          color: #fff;
          border-radius: 0.06rem;
          font-size: 0.16rem;
        }
        .showmore {
          font-size: 0.16rem;
          color: #737373;
          margin-top: 0.12rem;
          cursor: pointer;
          span:hover {
            color: #60c7c6;
            text-decoration: underline;
          }
        }
      }
      #visit {
        height: 1rem;
        // background-color: #a65464;
      }
      .tip {
        text-align: center;
        color: #999;
        font-size: 0.16rem;
      }
      .ant-radio-inner::after {
        background-color: #60c7c6;
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #60c7c6;
      }
      .ant-tabs-nav {
        display: block;
        margin: 0 auto;
        width: 2.2rem;
        margin-left: 3.2rem;
      }
      .ant-tabs-tab {
        padding: 0.1rem 0;
        margin: 0 0.6rem;
        color: #171717;
      }
      .ant-tabs-tab:hover {
        color: #60c7c6;
      }
      .ant-tabs-tab-active {
        color: #60c7c6;
        border-bottom: 3px solid #60c7c6;
      }
      .ant-tabs-ink-bar {
        background-color: #60c7c6;
        visibility: hidden;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #60c7c6;
        border-color: #60c7c6;
      }
      .form-title {
        border-top: 1px solid #f2f2f2;
        padding-top: 0.26rem;
        padding-bottom: 0.2rem;
        width: 10.66rem;
        margin: 0 auto;
      }
      .location {
        display: flex;
        align-items: center;
        padding-left: 0.2rem;
        position: relative;
        .box {
          width: 1.3rem;
          height: 0.33rem;
          border: 1px solid #60c7c6;
          border-radius: 0.03rem;
          color: #737373;
          font-size: 0.16rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.14rem;
          cursor: pointer;
        }
        .moren {
          width: 0.56rem;
          height: 0.22rem;
          border-radius: 0.03rem;
          background-color: #60c7c6;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0.14rem;
        }
        .addlocation {
          position: absolute;
          right: 0;
          top: -0.4rem;
          color: #60c7c6;
          font-size: 0.16rem;
          cursor: pointer;
        }
      }
      .table-content {
        width: 100%;
        margin-bottom: 0.2rem;
        td {
          text-align: center;
        }
      }
      .table-title {
        width: 100%;
        border-bottom: 1px solid #60c7c6;
        td {
          font-size: 0.16rem;
          color: #171717;
          padding-bottom: 0.1rem;
          vertical-align: middle;
        }
      }
      .list-item {
        color: #171717;
        #num {
          display: inline-block;
          // width: 0.5rem;
          border: 1px solid #ccc;
          user-select: none;
          .mountOpera {
            display: inline-block;
            // width: 0.1rem;
            background-color: #f1f1f1;
            // padding: 0 0.04rem;
            width: 0.15rem;
            cursor: pointer;
            text-align: center;
          }
        }
        .title {
          height: 0.4rem;
          line-height: 0.4rem;
        }
        .item-row {
          padding-bottom: 0.4rem;
          position: relative;

          .left-box {
            padding-left: 0.15rem;
            text-align: left;
            .img {
              width: 1.2rem;
              height: 0.68rem;
              // float: left;
              display: inline-block;
              margin-right: 0.1rem;
            }
          }
        }
      }
      .fapiao-box {
        display: flex;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #f2f2f2;
        .left {
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #60c7c6;
            border-color: #60c7c6;
          }
          .form-box {
            ul {
              margin-left: -40px;
              list-style-type: none;
              li {
                color: #737373;
                font-size: 0.16rem;

                line-height: 0.41rem;
              }
              li > span:nth-child(1) {
                display: inline-block;
                padding-right: 0.15rem;
                width: 1.5rem;
                text-align: right;
                color: #737373;
                height: 0.3rem;
                line-height: 0.4rem;
                font-size: 0.16rem;
                float: left;
              }
            }
          }
        }
        .right {
          width: 3.5rem;
          border-left: 1px solid #f2f2f2;
          padding-left: 0.2rem;
          .youhui {
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.16rem;
            padding-top: 0.05rem;
            font-size: 0.16rem;
            color: #171717;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 0.18rem;
          }
          .youhui:last-child {
            border: 0;
          }
        }
      }
      .paytype {
        display: flex;
        padding-left: 0.2rem;
        .box {
          width: 1rem;
          height: 0.33rem;
          border-radius: 0.03rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #b2b2b2;
          color: #171717;
          font-size: 0.16rem;
          margin-right: 0.25rem;
          margin-bottom: 0.2rem;
          cursor: pointer;
        }
        .active {
          border-color: #60c7c6;
          color: #60c7c6;
        }
      }
    }
    .paymoney {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      p {
        margin-bottom: 0.06rem;
        font-size: 0.16rem;
      }
      .left {
        text-align: right;
        color: #737373;
      }
      .right {
        text-align: right;
        color: #171717;
        width: 1.57rem;
        padding-right: 0.45rem;
      }
    }
    .end {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 0.42rem;
      background-color: #f8f8f8;
      padding-bottom: 0.2rem;
      padding-top: 0.23rem;
      .money {
        display: flex;
        justify-content: flex-end;
        font-size: 0.16rem;
        color: #737373;
        font-weight: bold;
        p {
          margin-bottom: 0.12rem;
        }
      }
    }
    .ant-btn-primary {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }
  }
}
#addAddressBox {
  margin-top: 0.2rem;
  .ant-row {
    height: 0.35rem;
  }
  .ant-checkbox-wrapper {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 1.25rem;
    .ant-checkbox-checked::after {
      border: 1px solid #87c7c6;
    }
  }
  .ant-cascader-menu-item-expand {
    font-size: 0.14rem;
  }
  .ant-cascader-menu-item {
    padding: 0.05rem 0.12rem;
    font-size: 0.14rem;
  }
  input {
    height: 0.4rem;
  }
}
</style>
